import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import Loading from '../components/Loading';

import { useInput } from '@domatic/hooks';

import Name from './Name';
import Door from './Door';
import { PortControl } from './PortControl';

import { useUpdateHub, useBuildings, useRole, useAdmin } from '@domatic/query';

import { ErrorList } from '../components';

const Detail = ({ hub }) => {

    const hasHubAccess = useRole('hubAccess');
    const isAdmin = useAdmin();

    const { data: buildings } = useBuildings();
    const building = buildings?.find(b => b.id === hub.building);
    const buildingName = (building && building.name) || 'Uncaptured';
    const { mutate: updateHub, isLoading: saving, error: updateError } = useUpdateHub();

    const build = useInput(hub.version, '', 'Version', hub.id);

    const doForget = () => updateHub({
        id: hub.id,
        building: null
    });

    const changes = build.changed;

    return (
        <Box p={2} width="100%">
            <Loading active={saving} spinner text="Saving...">
                <Name hub={hub} />
                {(isAdmin || hasHubAccess) && <Door hub={hub} />}
                <br />
                <Typography>hostname: {hub.hostname}</Typography>
                <Typography>tag: {hub.tag}</Typography>
                <Typography>MAC: {hub.mac}</Typography>
                <Typography>created: {hub.created}</Typography>
                <Typography>building: {buildingName}</Typography>
                <br />
                <br />
                {hub.building && isAdmin && <Button onClick={doForget} color='secondary' disabled={changes}>Forget</Button>}
                <br />
                <br />
                {isAdmin && <PortControl hub={hub} />}
                <br />
                <br />
                <ErrorList errors={[updateError]} />
            </Loading>
        </Box>
    );
};

Detail.propTypes = {
    hub: PropTypes.object
};

Detail.defaultProps = {};

export default Detail;
