import PropTypes from 'prop-types';
import Detail from './Detail';
import Location from './Location';
import Devices from './Devices';
import Zeus from './Zeus';
import Migration from './Migration';
import Circuits from './Circuits';
import Logging from './Logging';
import Emergency from './Emergency';
import Wifi from './Wifi';
import Update from './Update';
import Summary from './Summary';

import { Vertical, Horizontal, Header, Body } from '../components/Layout';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import SideBarMenu from '../components/SideBarMenu';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import UpdateIcon from '@material-ui/icons/Update';
import WifiIcon from '@material-ui/icons/Wifi';
import CircuitIcon from '@material-ui/icons/SettingsInputComponent';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { useAdmin } from '@domatic/query';

const Hub = ({ hub, path }) => {

    const { url } = useRouteMatch();
    const isAdmin = useAdmin();

    return (
        <Vertical>
            <Header>
                <Summary hub={hub} />
                <br />
            </Header>
            <Body>
                <Horizontal>
                    <Header>
                        <SideBarMenu items={[
                            {
                                primaryText: 'Detail',
                                icon: <DeviceHubIcon />,
                                path: `${url}/detail`
                            },
                            {
                                primaryText: 'Location',
                                icon: <LocationOnIcon />,
                                path: `${url}/location`
                            },
                            {
                                primaryText: 'Emergency',
                                icon: <ErrorIcon />,
                                path: `${url}/emergency`
                            },
                            {
                                primaryText: 'WiFi AP',
                                icon: <WifiIcon />,
                                path: `${url}/wifi`
                            },
                            {
                                primaryText: 'Logging',
                                icon: <WarningIcon />,
                                path: `${url}/logging`,
                                admin: true
                            },
                            {
                                primaryText: 'Update',
                                icon: <UpdateIcon />,
                                path: `${url}/update`,
                                admin: true
                            },
                            {
                                primaryText: 'Zeus',
                                icon: <CircuitIcon />,
                                path: `${url}/zeus`
                            },
                            {
                                primaryText: 'Migration',
                                icon: <SwapHorizIcon />,
                                path: `${url}/migration`
                            },
                            {
                                primaryText: 'Devices',
                                icon: <WbIncandescentIcon />,
                                path: `${url}/devices`
                            },
                            {
                                primaryText: 'Circuits',
                                icon: <CircuitIcon />,
                                path: `${url}/circuits`
                            }
                        ].filter(item => !item.admin || isAdmin)} />
                    </Header>

                    <Body>
                        <Vertical>
                            <Body>
                                <Switch>
                                    <Route path={`${path}/detail`}>
                                        <Detail hub={hub} />
                                    </Route>
                                    <Route path={`${path}/location`}>
                                        <Location hub={hub} />
                                    </Route>
                                    <Route path={`${path}/emergency`}>
                                        <Emergency hub={hub} />
                                    </Route>
                                    <Route path={`${path}/wifi`}>
                                        <Wifi hub={hub} />
                                    </Route>
                                    <Route path={`${path}/logging`}>
                                        <Logging hub={hub} />
                                    </Route>
                                    <Route path={`${path}/update`}>
                                        <Update hub={hub} />
                                    </Route>
                                    <Route path={`${path}/zeus`}>
                                        <Zeus hub={hub} />
                                    </Route>
                                    <Route path={`${path}/migration`}>
                                        <Migration hub={hub} />
                                    </Route>
                                    <Route path={`${path}/devices`}>
                                        <Devices hub={hub} />
                                    </Route>
                                    <Route path={`${path}/circuits`}>
                                        <Circuits hub={hub} />
                                    </Route>
                                    <Route path={path}>
                                        <Redirect to={`${path}/detail`} />
                                    </Route>
                                </Switch>
                            </Body>
                        </Vertical>
                    </Body>
                </Horizontal>
            </Body>
        </Vertical>);
};

Hub.propTypes = {
    hub: PropTypes.object,
    path: PropTypes.string
};

Hub.defaultProps = {};

export default Hub;
