import { getCachedHub, getUser } from '@domatic/query';

const useRoutes = () => {
    const routes = [
        {
            path: 'home',
            name: 'Home',
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard'
                },
                {
                    path: 'admin',
                    name: 'Admin',
                    children: [
                        {
                            path: 'uncaptured',
                            name: 'Uncaptured'
                        },
                        {
                            path: 'groups',
                            name: 'Groups'
                        },
                        {
                            path: 'builds',
                            name: 'Builds'
                        },
                        {
                            path: 'users',
                            name: 'Users',
                            children: [{
                                path: 'default',
                                name: id => getUser(id)?.name || id,
                                children: [{
                                    path: 'edit',
                                    name: 'Edit'
                                }]
                            }]
                        },
                        {
                            path: 'jobs',
                            name: 'Job Manager'
                        },
                        {
                            path: 'info',
                            name: 'Info'
                        }
                    ]
                },
                {
                    path: 'catalog',
                    name: 'Catalog',
                    children: [{
                        path: 'fixtures',
                        name: 'Fixtures',
                        children: [{
                            path: 'add',
                            name: 'Add'
                        },
                        {
                            path: 'default',
                            name: id => id,
                            children: [
                                {
                                    path: 'edit',
                                    name: 'Edit'
                                }
                            ]
                        }]
                    }, {
                        path: 'drivers',
                        name: 'Drivers',
                        children: [{
                            path: 'add',
                            name: 'Add'
                        },
                        {
                            path: 'default',
                            name: id => id,
                            children: [
                                {
                                    path: 'edit',
                                    name: 'Edit'
                                }
                            ]
                        }]
                    }, {
                        path: 'products',
                        name: 'Products',
                        children: [{
                            path: 'add',
                            name: 'Add'
                        },
                        {
                            path: 'default',
                            name: id => id,
                            children: [
                                {
                                    path: 'edit',
                                    name: 'Edit'
                                }
                            ]
                        }]
                    }]
                },
                {
                    path: 'organization',
                    name: 'Organization',
                    children: [
                        {
                            path: 'default',
                            name: 'Detail'
                        },
                        {
                            path: 'members',
                            name: 'Members'
                        },
                        {
                            path: 'invites',
                            name: 'Invitations'
                        },
                        {
                            path: 'buildings',
                            name: 'Buildings',
                            children: [{
                                path: 'add',
                                name: 'Add'
                            }]
                        }
                    ]
                },
                {
                    path: 'building',
                    name: 'Building',
                    children: [
                        {
                            path: 'detail',
                            name: 'Detail'
                        },
                        {
                            path: 'contacts',
                            name: 'Contacts',
                            children: [
                                {
                                    path: 'add',
                                    name: 'Add'
                                },
                                {
                                    path: 'edit',
                                    name: 'Edit'
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'hubs',
                    name: 'Hubs',
                    children: [
                        {
                            path: 'default',
                            name: id => getCachedHub(id)?.name ?? 'Unknown',
                            children: [
                                {
                                    path: 'detail',
                                    name: 'Detail'
                                },
                                {
                                    path: 'location',
                                    name: 'Location'
                                },
                                {
                                    path: 'emergency',
                                    name: 'Emergency'
                                },
                                {
                                    path: 'wifi',
                                    name: 'Wifi'
                                },
                                {
                                    path: 'logging',
                                    name: 'Logging'
                                },
                                {
                                    path: 'update',
                                    name: 'Update'
                                },
                                {
                                    path: 'zeus',
                                    name: 'Zeus'
                                },
                                {
                                    path: 'devices',
                                    name: 'Devices'
                                },
                                {
                                    path: 'switches',
                                    name: 'Switches'
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'manage',
                    name: 'Manage',
                    children: [
                        {
                            path: 'faults',
                            name: 'Faults'
                        },
                        {
                            path: 'water',
                            name: 'Water'
                        },
                        {
                            path: 'power',
                            name: 'Power'
                        }
                    ]
                },
                {
                    path: 'settings',
                    name: 'Settings',
                    children: [
                        {
                            path: 'password',
                            name: 'Change Password',
                        }
                    ]
                }
            ]
        }
    ];
    return routes;
};

export default useRoutes;
